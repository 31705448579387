import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

const Purchases = () => {
  const [purchasedCourses, setPurchasedCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const beUrl = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : "https://server.coursebidder.com";

  const getPurchasedCourses = async () => {
    try {
      setLoading(true);
      // Ensure backend returns all purchased listings, including "Dropped"
      const apiUrl = `${beUrl}/get-purchases`;
      const response = await axios.get(apiUrl, { withCredentials: true });
      setPurchasedCourses(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getPurchasedCourses();
  }, []);

  const handleChat = (item) => {
    if (item.status === "Finalized") {
      toast.info("Chat is disabled for finalized purchases.");
    } else if (item.chatId) {
      navigate(`/chat/${item.chatId}`);
    } else {
      toast.error("Chat not available for this purchase");
    }
  };

  // Updated finalizeListing: call backend to update listing status to "Finalized"
  const finalizeListing = async (listingId) => {
    try {
      const response = await axios.put(
        `${beUrl}/finalize-purchase/${listingId}`,
        {},
        { withCredentials: true }
      );
      toast.success(response.data.message);
      // Refresh the purchased courses list after finalizing
      getPurchasedCourses();
    } catch (error) {
      console.error("Error finalizing listing:", error);
      toast.error("Error finalizing listing");
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Available":
        return "text-green-500";
      case "Waiting for Approval":
        return "text-yellow-500";
      case "Bought":
        return "text-blue-500";
      case "Dropped":
        return "text-red-500";
      case "Denied":
        return "text-gray-500";
      default:
        return "text-yellow-500";
    }
  };

  const paymentStatusColor = (status) => {
    switch (status) {
      case "paid":
        return "text-green-500";
      case "failed":
        return "text-yellow-500";
      default:
        return "text-yellow-500";
    }
  };

  return (
    <div className="max-w-container mx-auto px-4 pb-16">
      <Breadcrumbs title="Purchases" />
      {loading ? (
        <div className="w-full flex items-center justify-center py-20">
          <div className="loader"></div>
        </div>
      ) : purchasedCourses?.length > 0 ? (
        <div className="grid grid-cols-1 gap-8">
          {purchasedCourses.map((item) => (
            <motion.div
              key={item._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row items-start md:items-center gap-4"
            >
              <div className="flex-1">
                <h2 className="text-2xl font-semibold mb-2">
                  {item.isClassListing
                    ? `Class: ${item.className}`
                    : "Offering to Hold Class"}
                </h2>
                {/* Display college name */}
                <p className="text-gray-700 mb-1">College: {item.collegeName}</p>
                <p className="text-gray-700 mb-1">
                  Price: <span className="font-bold">${item.price}</span>
                </p>
                <p className="text-gray-700 mb-1">Additional Notes: {item.notes}</p>
                <p className="text-gray-700 mb-1">
                  Last Updated:{" "}
                  {item.timeLastUpdated ? moment(item.timeLastUpdated).format("LLL") : "N/A"}
                </p>
                <p className="text-gray-500 mb-1">
                  Purchased on:{" "}
                  {item.purchasedOn ? moment(item.purchasedOn).format("LLL") : "N/A"}
                </p>
                {item.transaction && (
                  <div className="mt-2 border-t pt-2">
                    <p className="text-gray-700">
                      Payment Status:{" "}
                      <span className={`font-bold ${paymentStatusColor(item.transaction.paymentStatus)}`}>
                        {item.transaction.paymentStatus
                          .toLowerCase()
                          .split(" ")
                          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(" ")}
                      </span>
                    </p>
                    <p className="text-gray-700">
                      Transaction Amount: <span className="font-bold">${item.transaction.amount}</span>{" "}
                      {item.transaction.currency}
                    </p>
                    <p className="text-gray-700">
                      Transaction Date:{" "}
                      {item.transaction.createdAt ? moment(item.transaction.createdAt).format("LLL") : "N/A"}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-4">
                {/* Display status */}
                <div>
                  <p className={`font-bold ${getStatusColor(item.status)}`}>Status: {item.status}</p>
                </div>
                <div className="flex flex-wrap gap-2">
                  {/* Show Chat button if listing is not Finalized */}
                  {item.status !== "Finalized" && (
                    <>
                      <button
                        onClick={() => handleChat(item)}
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                      >
                        Chat
                      </button>
                      {/* For dropped listings, also show Finalize button */}
                      {item.status === "Dropped" && (
                        <button
                          onClick={() => finalizeListing(item._id)}
                          className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-md"
                        >
                          I've picked up the class
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-20">
          <h1 className="text-2xl font-bold text-gray-700">No Classes Purchased</h1>
          <p className="text-gray-500 mt-2">You haven't purchased any classes yet.</p>
        </div>
      )}
    </div>
  );
};

export default Purchases;
