// src/pages/Faq.js
import React from "react";

const Faq = () => {
  return (
    <div className="w-full h-full p-10 bg-gray-50">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-900">
        Frequently Asked Questions (FAQ)
      </h1>
      <div className="space-y-8 max-w-4xl mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            1. What kind of payments does CourseBidder accept?
          </h2>
          <p className="text-lg text-gray-700">
            We accept major credit and debit cards processed securely through
            Braintree. Our system also holds funds in escrow until the transaction
            is finalized to ensure both buyer and seller are protected. Additional
            payment options such as PayPal may be added in the near future.
          </p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            2. How can I be sure that I get the class or get paid when I sell a class?
          </h2>
          <p className="text-lg text-gray-700">
            Funds are held securely in escrow until both parties confirm that the
            class exchange is complete. This means that you only receive your payment
            or gain access to the class when the transaction is verified by both
            buyer and seller. If there’s any issue, our refund process ensures your money
            is returned.
          </p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            3. How do I know my data is safe?
          </h2>
          <p className="text-lg text-gray-700">
            Your security is our top priority. We use industry-standard encryption,
            secure cookies, and maintain minimal data retention. All sensitive
            payment details are handled by our trusted payment gateway so that we
            never store your full payment information on our servers.
          </p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            4. How do I buy a course?
          </h2>
          <p className="text-lg text-gray-700">
            Simply browse our listings, add your chosen course to your cart, and
            proceed to checkout. During checkout, you’ll be charged and your funds will
            be held in escrow. You’ll then have access to a secure, anonymous chat with
            the seller to finalize the details of your purchase.
          </p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            5. How long do I have to wait before the seller confirms my purchase?
          </h2>
          <p className="text-lg text-gray-700">
            Once you complete your purchase, the seller has up to 72 hours to confirm
            and process the transaction. If no action is taken within that period, your
            payment will be automatically refunded.
          </p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            6. What happens if there's a dispute or the seller fails to respond?
          </h2>
          <p className="text-lg text-gray-700">
            In the event of a dispute or if the seller does not respond within the
            72-hour window, the funds held in escrow will be returned to the buyer.
            Our support team is also available to help resolve any issues quickly and
            fairly.
          </p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-2 text-blue-700">
            7. What additional features does CourseBidder offer?
          </h2>
          <p className="text-lg text-gray-700">
            CourseBidder is designed with your security and convenience in mind.
            Our platform features AI verification for listings, a secure anonymous
            chat system for buyer-seller communication, and a robust payment process
            with funds held in escrow until final confirmation. We’re constantly working
            to improve the experience, ensuring smooth and safe transactions for everyone.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Faq;
