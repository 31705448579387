import React, { useState, useEffect } from "react";
import axios from "axios";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useOutletContext, useNavigate } from "react-router-dom";

function EachListing({
  _id,
  price,
  timeLastUpdated,
  status,
  collegeName,
  isClassListing,
  className,
  notes,
  seller, // populated object with seller.email
  setProfileData,
  profileData,
}) {
  const navigate = useNavigate();
  const sellerEmail = seller?.email || "N/A";

  // When the Purchase button is clicked, redirect to /purchasepage with listing details.
  const handlePurchase = () => {
    const listing = {
      _id,
      sellerEmail,
      price,
      timeLastUpdated,
      status,
      collegeName,
      isClassListing,
      className,
      notes,
      seller,
    };
    navigate("/purchasepage", { state: [listing] });
  };

  return (
    <div
  className="bg-gray-200 p-6 m-4 rounded-lg shadow-md hover:shadow-2xl transition-all duration-300 transform hover:scale-105 cursor-pointer"
  onClick={handlePurchase}
>
  <div className="space-y-4">
    <div>
      {isClassListing ? (
        <h2 className="text-3xl font-bold text-blue-900">{className}</h2>
      ) : (
        <h2 className="text-3xl font-bold text-blue-900">
          Offering to Hold a Class
        </h2>
      )}
    </div>
    <div>
      <p className="text-2xl font-bold text-gray-700">Price: ${price}</p>
    </div>
  </div>
  <div className="space-y-2 mt-4">
    <p className="text-lg text-gray-500">College: {collegeName}</p>
    <p className="text-md text-gray-500">Status: {status}</p>
    <p className="text-md text-gray-600">Notes: {notes}</p>
    <p className="text-sm text-gray-400">
      Updated:{" "}
      {`${new Date(timeLastUpdated).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      })}, ${new Date(timeLastUpdated).toLocaleDateString("en-US")}`}
    </p>
  </div>
</div>
  );
}

function Items({ currentItems, setProfileData, profileData }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {currentItems &&
        currentItems.map((item) => (
          <EachListing
            key={item._id}
            {...item}
            setProfileData={setProfileData}
            profileData={profileData}
          />
        ))}
    </div>
  );
}

const Home = () => {
  const { profileData, setProfileData } = useOutletContext();

  const [apiData, setApiData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({ college: "", type: "", class: "" });
  const [sort, setSort] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [loading, setLoading] = useState(true);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
  };

  const handleResetFilters = () => {
    setFilters({ college: "", type: "", class: "" });
    setSort("");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";
        const response = await axios.get(`${beUrl}/getListings`, {
          withCredentials: true,
        });
        setApiData(response.data.data);
        setFilteredData(response.data.data);
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    const fetchUserData = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";
        const userResponse = await axios.get(`${beUrl}/getUserFromSession`, {
          withCredentials: true,
        });
        setUserEmail(userResponse.data.email);
      } catch (error) {
        console.error("API call error:", error);
      }
    };

    setTimeout(() => {
      setLoading(false);
    }, 500);

    fetchData();
    fetchUserData();
  }, []);

  useEffect(() => {
    let filtered = apiData
      // Exclude listings where seller.email matches the current user’s email.
      .filter((item) => !userEmail || item.seller?.email !== userEmail)
      .filter((item) => !filters.college || item.collegeName === filters.college)
      .filter(
        (item) =>
          !filters.type ||
          (filters.type === "Class" && item.isClassListing) ||
          (filters.type === "Offer" && !item.isClassListing)
      )
      .filter((item) => !filters.class || item.className === filters.class);

    if (sort === "price") {
      filtered.sort((a, b) => a.price - b.price);
    } else if (sort === "college") {
      filtered.sort((a, b) => a.collegeName.localeCompare(b.collegeName));
    } else if (sort === "type") {
      filtered.sort((a, b) => a.isClassListing - b.isClassListing);
    }

    setFilteredData(filtered);
  }, [filters, sort, apiData, userEmail]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <p className="text-2xl font-semibold text-gray-700">Loading...</p>
      </div>
    );
  }

  if (!apiData.length) {
    return (
      <div className="flex flex-col items-center justify-center h-full py-20">
        <p className="text-2xl font-semibold text-gray-700">
          No classes listed yet. Be the first one!
        </p>
      </div>
    );
  }

  const filteredApiDataForFilters = apiData.filter(
    (item) => !userEmail || item.seller?.email !== userEmail
  );

  const colleges = [
    ...new Set(filteredApiDataForFilters.map((item) => item.collegeName)),
  ].map((college) => ({
    name: college,
    count: filteredApiDataForFilters.filter(
      (item) => item.collegeName === college
    ).length,
  }));

  const types = [
    {
      name: "Class",
      count: filteredApiDataForFilters.filter((item) => item.isClassListing)
        .length,
    },
    {
      name: "Offer",
      count: filteredApiDataForFilters.filter((item) => !item.isClassListing)
        .length,
    },
  ];

  const classes = [
    ...new Set(filteredApiDataForFilters.map((item) => item.className)),
  ].map((className) => ({
    name: className,
    count: filteredApiDataForFilters.filter(
      (item) => item.className === className
    ).length,
  }));

  return (
    <div className="p-6">
      <Breadcrumbs
        title="Home - All Course Listings"
        undercard="Search for new listings to purchase"
      />
      <div className="mb-6">
        <div className="w-full bg-blue-300 py-2 rounded-lg mx-auto lg:mx-4">
          <div className="px-2 lg:px-4">
            <div className="flex flex-col lg:flex-row items-start lg:items-center justify-between">
              <div className="flex flex-wrap gap-2 lg:gap-4 mt-1 w-full lg:w-auto">
                <div className="w-full lg:w-auto relative">
                  <select
                    name="college"
                    value={filters.college}
                    onChange={handleFilterChange}
                    className="w-full lg:w-auto px-3 py-2 rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">All Colleges</option>
                    {colleges.map((college) => (
                      <option key={college.name} value={college.name}>
                        {college.name} ({college.count})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full lg:w-auto relative">
                  <select
                    name="type"
                    value={filters.type}
                    onChange={handleFilterChange}
                    className="w-full lg:w-auto px-3 py-2 rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">All Listing Types</option>
                    {types.map((type) => (
                      <option key={type.name} value={type.name}>
                        {type.name} ({type.count})
                      </option>
                    ))}
                  </select>
                </div>
                <div className="w-full lg:w-auto relative">
                  <select
                    name="class"
                    value={filters.class}
                    onChange={handleFilterChange}
                    className="w-full lg:w-auto px-3 py-2 rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">All Classes</option>
                    {classes.map((classItem) => (
                      <option key={classItem.name} value={classItem.name}>
                        {classItem.name} ({classItem.count})
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="flex items-center justify-end w-full lg:w-auto mt-2 gap-4">
                <button
                  onClick={handleResetFilters}
                  className="px-3 py-2 text-sm rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  Reset Filters
                </button>
                <div className="flex items-center">
                  <label className="block text-sm font-medium text-gray-700 mr-2">
                    Sort by:
                  </label>
                  <select
                    value={sort}
                    onChange={handleSortChange}
                    className="px-3 py-2 text-sm rounded-full bg-white shadow-sm border border-gray-300 hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">None</option>
                    <option value="price">Price</option>
                    <option value="college">College</option>
                    <option value="type">Type</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Items
        currentItems={filteredData}
        setProfileData={setProfileData}
        profileData={profileData}
      />
    </div>
  );
};

export default Home;
