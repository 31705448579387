import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { logo } from "../../../assets/images";
import Image from "../../designLayouts/Image";
import Flex from "../../designLayouts/Flex";
import { googleLogout, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateEmail } from "../../../redux/userDetails";

function signOut(email) {
  googleLogout(); // Log out of Google
  const beUrl = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : "https://server.coursebidder.com";

  return fetch(`${beUrl}/logout`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ email }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Logout failed");
      }
      return response.json();
    })
    .then((data) => {
      document.cookie = "session=; Max-Age=0; path=/;";
      window.location.href = "/home";
      return data;
    })
    .catch((error) => {
      console.error("Error logging user out:", error);
    });
}

function getProfile() {
  console.log("Hello User Data");
  const beUrl = process.env.REACT_APP_BACKEND_URL
    ? process.env.REACT_APP_BACKEND_URL
    : "https://server.coursebidder.com";

  return fetch(`${beUrl}/getUserFromSession`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch profile");
      }
      return response.json();
    })
    .then((profileData) => {
      console.log("User Data:", profileData);
      return profileData;
    })
    .catch((error) => {
      console.error("Error fetching profile:", error);
    });
}

const Header = ({ profileData, setProfileData }) => {
  const dispatch = useDispatch();
  const [navBarList, setNavBarList] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    getProfile().then((data) => {
      setProfileData(data);
      dispatch(updateEmail(data?.email));
    });
  }, [dispatch]);

  useEffect(() => {
    const isLoggedIn = Boolean(profileData && profileData.email);
    const tempNavBarList = [
      {
        _id: 1001,
        title: "Home",
        link: "/home",
        showIfLoggedOut: true,
        showIfLoggedIn: true,
      },
      {
        _id: 1002,
        title: "My Listings",
        link: "/userListings",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1003,
        title: "Admin",
        link: "/admin",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1004,
        title: "Add Listing",
        link: "/addListing",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1006,
        title: "My Purchases",
        link: "/purchases",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1007,
        title: "Sign In with Google",
        link: "/home",
        showIfLoggedOut: true,
        showIfLoggedIn: false,
      },
      {
        _id: 1009,
        title: "Sign Out",
        link: "/home",
        showIfLoggedOut: false,
        showIfLoggedIn: true,
      },
      {
        _id: 1008,
        title: "FAQ",
        link: "/faq",
        showIfLoggedOut: true,
        showIfLoggedIn: true,
      },
    ];

    const updatedNavBarList = tempNavBarList.filter((item) => {
      if (!isLoggedIn) return item.showIfLoggedOut;
      if (item.title === "Admin") return profileData?.isAdmin;
      return item.showIfLoggedIn;
    });

    setNavBarList(updatedNavBarList);
  }, [profileData]);

  const handleGoogleLoginSuccess = async (response) => {
    try {
      const { credential } = response;
      console.log("Google credential received:", credential);
      const beUrl = process.env.REACT_APP_BACKEND_URL
        ? process.env.REACT_APP_BACKEND_URL
        : "https://server.coursebidder.com";
      const res = await axios.post(
        `${beUrl}/google-login`,
        { token: credential },
        { withCredentials: true }
      );
      console.log("Google login response status:", res.status);
      console.log("Google login response data:", res.data);

      if (res.status === 201 && res.data.message === "Google login successful") {
        window.location.href = "/home";
      } else {
        console.error(
          "Google login failed with status:",
          res.status,
          "and message:",
          res.data.message
        );
      }
    } catch (error) {
      console.error("Error with Google login:", error);
    }
  };

  const handleGoogleLoginFailure = (error) => {
    console.error("Google login error:", error);
  };

  return (
    <header className="w-full h-20 bg-white shadow-md sticky top-0 z-50 border-b border-gray-300">
      <nav className="h-full px-4 max-w-container mx-auto relative">
        <Flex className="flex items-center justify-between h-full">
          <Link to="/home">
            <div>
              <Image className="w-60 object-cover" imgSrc={logo} />
            </div>
          </Link>
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-2xl md:hidden"
          >
            {isMobileMenuOpen ? "✖" : "☰"}
          </button>
          {/* Desktop Menu */}
          <div className="hidden md:flex">
            <motion.ul
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="flex items-center gap-4"
            >
              {navBarList.map(({ _id, title, link }) => (
                <NavLink
                  key={_id}
                  to={link}
                  className={({ isActive }) =>
                    `tracking-wide px-6 py-2 text-xl transition-colors duration-300 ${
                      isActive ? "font-bold underline" : "font-normal"
                    } hover:bg-blue-100 hover:text-blue-700 md:border-r-2 md:border-gray-300 last:md:border-r-0`
                  }
                  state={{ data: location.pathname.split("/")[1] }}
                  onClick={(e) => {
                    if (title === "Sign Out") {
                      e.preventDefault();
                      signOut(profileData.email);
                    }
                  }}
                >
                  {title === "Sign In with Google" ? (
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginFailure}
                      render={({ onClick, disabled }) => (
                        <button
                          onClick={() => {
                            alert("Only UCLA emails can sign in");
                            onClick();
                          }}
                          disabled={disabled}
                          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg transition-colors duration-300 hover:bg-blue-500"
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                            alt="Google logo"
                            className="w-6 h-6 mr-2"
                          />
                          Sign In with Google
                        </button>
                      )}
                    />
                  ) : (
                    <span>{title}</span>
                  )}
                </NavLink>
              ))}
            </motion.ul>
          </div>
        </Flex>
        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="md:hidden absolute top-full left-0 w-full bg-white shadow-md">
            <motion.ul
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="flex flex-col items-center gap-4 py-4"
            >
              {navBarList.map(({ _id, title, link }) => (
                <NavLink
                  key={_id}
                  to={link}
                  className={({ isActive }) =>
                    `tracking-wide px-6 py-2 text-xl transition-colors duration-300 ${
                      isActive ? "font-bold underline" : "font-normal"
                    } hover:bg-blue-100 hover:text-blue-700`
                  }
                  state={{ data: location.pathname.split("/")[1] }}
                  onClick={(e) => {
                    if (title === "Sign Out") {
                      e.preventDefault();
                      signOut(profileData.email);
                    }
                    setIsMobileMenuOpen(false);
                  }}
                >
                  {title === "Sign In with Google" ? (
                    <GoogleLogin
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginFailure}
                      render={({ onClick, disabled }) => (
                        <button
                          onClick={() => {
                            alert("Only UCLA emails can sign in");
                            onClick();
                          }}
                          disabled={disabled}
                          className="flex items-center justify-center px-6 py-3 bg-blue-600 text-white rounded-full shadow-lg transition-colors duration-300 hover:bg-blue-500"
                        >
                          <img
                            src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                            alt="Google logo"
                            className="w-6 h-6 mr-2"
                          />
                          Sign In with Google
                        </button>
                      )}
                    />
                  ) : (
                    <span>{title}</span>
                  )}
                </NavLink>
              ))}
            </motion.ul>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
