import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import axios from "axios";
import { useParams, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";

const Chat = () => {
  const { chatId } = useParams();
  const { profileData } = useOutletContext(); // Get user data from Layout context
  const currentUserId = profileData?._id;
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");

  // New state to hold the full chat object (which includes listingId)
  const [chatData, setChatData] = useState(null);
  // New state to hold the listing details for this chat
  const [listingData, setListingData] = useState(null);

  const beUrl =
    process.env.REACT_APP_BACKEND_URL
      ? process.env.REACT_APP_BACKEND_URL
      : "https://server.coursebidder.com";

  // Fetch chat details on mount (including messages and listingId)
  useEffect(() => {
    const fetchChat = async () => {
      try {
        const response = await axios.get(`${beUrl}/getChat/${chatId}`, {
          withCredentials: true,
        });
        // Save the full chat data and also set the messages array
        setChatData(response.data);
        setMessages(response.data.messages || []);
      } catch (error) {
        console.error("Error fetching chat history:", error);
      }
    };
    if (chatId) {
      fetchChat();
    }
  }, [chatId, beUrl]);

  // Once we have the chat data and listingId, fetch listing details
  useEffect(() => {
    const fetchListingDetails = async () => {
      if (chatData && chatData.listingId) {
        try {
          const response = await axios.get(
            `${beUrl}/getListing/${chatData.listingId}`,
            { withCredentials: true }
          );
          setListingData(response.data);
        } catch (error) {
          console.error("Error fetching listing details:", error);
        }
      }
    };
    fetchListingDetails();
  }, [chatData, beUrl]);

  // Initialize socket and set up listeners
  useEffect(() => {
    const newSocket = io(beUrl, {
      transports: ["websocket"],
    });
    setSocket(newSocket);
    newSocket.emit("joinChat", chatId);
    newSocket.on("messageReceived", (data) => {
      setMessages((prevMessages) => [...prevMessages, data]);
    });
    return () => newSocket.disconnect();
  }, [chatId, beUrl]);

  const sendMessage = () => {
    if (inputText.trim() === "" || !socket) return;

    const messageData = {
      chatId,
      sender: currentUserId,
      text: inputText,
      createdAt: new Date().toISOString(), // Attach timestamp once when sending
    };

    // Emit message to server; backend should persist the message with the provided timestamp
    socket.emit("sendMessage", messageData);
    setInputText("");
  };

  // Handler for seller to "drop" the class (confirm drop)
  const handleDrop = async () => {
    try {
      const response = await axios.put(
        `${beUrl}/confirm-drop/${listingData._id}`,
        {},
        { withCredentials: true }
      );
      toast.success(response.data.message);
      // Update the listing status locally to "Dropped"
      setListingData({ ...listingData, status: "Dropped" });
    } catch (error) {
      console.error("Error dropping class:", error);
      toast.error("Error dropping class");
    }
  };

  // Handler for buyer to finalize the purchase
  const handleFinalize = async () => {
    try {
      const response = await axios.put(
        `${beUrl}/finalize-purchase/${listingData._id}`,
        {},
        { withCredentials: true }
      );
      toast.success(response.data.message);
      // Update the listing status locally to "Finalized"
      setListingData({ ...listingData, status: "Finalized" });
    } catch (error) {
      console.error("Error finalizing purchase:", error);
      toast.error("Error finalizing purchase");
    }
  };

  return (
    <div className="max-w-3xl mx-auto my-8 shadow-lg rounded-lg overflow-hidden bg-white">
      <div className="bg-blue-600 text-white py-4 px-6">
        <h2 className="text-xl font-bold">Chat Room</h2>
      </div>
      <div className="p-4 h-96 overflow-y-auto bg-gray-50">
        {messages.length > 0 ? (
          messages.map((msg, index) => (
            <div
              key={index}
              className={`mb-3 p-2 rounded-md max-w-xs ${
                msg.sender === currentUserId
                  ? "bg-blue-100 self-end text-right ml-auto"
                  : "bg-gray-200 self-start text-left mr-auto"
              }`}
            >
              <p className="text-sm">{msg.text}</p>
              <span className="text-xs text-gray-600">
                {new Date(msg.createdAt).toLocaleTimeString()}
              </span>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">No messages yet.</p>
        )}
      </div>
      {/* Render Drop / Finalize buttons above the chat input if listing details are available */}
      {listingData && (
        <div className="flex justify-end space-x-2 p-2">
          {/* Show "Drop" button if current user is seller and listing is still bought */}
          {String(listingData.seller) === String(currentUserId) &&
            listingData.status === "Bought" && (
              <button
                onClick={handleDrop}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
              >
                I've dropped the class
              </button>
            )}
          {/* Show "Finalize" button if current user is buyer and listing is dropped */}
          {String(listingData.buyer) === String(currentUserId) &&
            listingData.status === "Dropped" && (
              <button
                onClick={handleFinalize}
                className="bg-purple-500 hover:bg-purple-600 text-white px-4 py-2 rounded-md"
              >
               I've picked up the class
              </button>
            )}
        </div>
      )}
      <div className="flex border-t p-4">
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
            }
          }}
          placeholder="Type your message..."
          className="flex-1 border rounded-l-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={sendMessage}
          className="bg-blue-600 text-white px-6 py-2 rounded-r-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;
