import React, { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Header from "./components/home/Header/Header";
import Footer from "./components/home/Footer/Footer";
import Purchases from "./pages/Purchases/Purchases";
import Home from "./pages/Home/Home";
import UserListings from "./pages/UserListing/UserListings";
import Admin from "./pages/Admin/Admin";
import AddListing from "./pages/UserListing/addListing";
import LandingPage from "./pages/Home/LandingPage";
import Error from "./pages/Error/Error";
import PurchasePage from "./pages/Purchases/PurchasePage";
import Terms from "./pages/Docs/terms";
import Privacy from "./pages/Docs/privacy";
import Faq from "./pages/Docs/Faq";
import Chat from "./pages/Chat/Chat";

const Layout = () => {
  const [profileData, setProfileData] = useState(null);
  return (
    <div>
      <Header profileData={profileData} setProfileData={setProfileData} />
      {/* Pass profileData and setProfileData via context */}
      <Outlet context={{ profileData, setProfileData }} />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<LandingPage />} />
      <Route path="home" element={<Home />} />
      <Route path="userListings" element={<UserListings />} />
      <Route path="purchases" element={<Purchases />} />
      <Route path="addListing" element={<AddListing />} />
      <Route path="admin" element={<Admin />} />
      <Route path="purchasepage" element={<PurchasePage />} />
      <Route path="terms" element={<Terms />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="faq" element={<Faq />} />
      <Route path="chat/:chatId" element={<Chat />} />
      <Route path="*" element={<Error />} />
    </Route>,
  ),
);

function App() {
  return (
    <GoogleOAuthProvider clientId="910662174030-olfra67g0e11irk9v6815cifrgsgg2dv.apps.googleusercontent.com">
      <div className="font-bodyFont">
        <RouterProvider router={router} />
      </div>
    </GoogleOAuthProvider>
  );
}

export default App;
