import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { SwipeableButton } from "react-swipeable-button";
import moment from "moment";

const PurchaseForm = ({ listing, setProfileData }) => {
  const navigate = useNavigate();
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [clientToken, setClientToken] = useState("");
  const [dropinInstance, setDropinInstance] = useState(null);
  const dropinContainerRef = useRef(null);

  // Fetch client token for Braintree
  useEffect(() => {
    const fetchClientToken = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";
        const { data } = await axios.get(`${beUrl}/client_token`, { withCredentials: true });
        setClientToken(data.clientToken);
      } catch (error) {
        console.error("Error fetching client token:", error);
        toast.error("Error loading payment information");
      }
    };
    fetchClientToken();
  }, []);

  // Initialize Braintree Drop-In
  useEffect(() => {
    if (clientToken && dropinContainerRef.current) {
      import("braintree-web-drop-in").then((dropin) => {
        dropin.create(
          {
            authorization: clientToken,
            container: dropinContainerRef.current,
          },
          (err, instance) => {
            if (err) {
              console.error("Dropin create error:", err);
              toast.error("Error initializing payment UI");
              return;
            }
            setDropinInstance(instance);
          }
        );
      });
    }
  }, [clientToken]);

  // Handle payment success and create chat between buyer and seller
  const handlePaymentSuccess = async () => {
    if (!dropinInstance) {
      toast.error("Payment UI not initialized");
      return;
    }
    try {
      setLoading(true);
      dropinInstance.requestPaymentMethod(async (err, payload) => {
        if (err) {
          console.error("Error requesting payment method:", err);
          toast.error("Error capturing payment details");
          setLoading(false);
          return;
        }
        const paymentMethodNonce = payload.nonce;
        const amount = parseFloat(listing.price).toFixed(2);
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";

        // Call Braintree checkout endpoint
        let braintreeResponse;
        try {
          const { data } = await axios.post(
            `${beUrl}/braintree/checkout`,
            { paymentMethodNonce, amount, listingId: listing._id },
            { withCredentials: true }
          );
          braintreeResponse = data;
        } catch (btError) {
          console.error("Braintree checkout error:", btError);
          toast.error("Payment processing error (Braintree): " + (btError.response?.data?.error || btError.message));
          setLoading(false);
          return;
        }

        if (braintreeResponse && braintreeResponse.transaction && braintreeResponse.transaction.id) {
          toast.success("Payment successful!");

          // Call backend /checkout endpoint to update purchase and create chat
          let checkoutResponse;
          try {
            const { data } = await axios.post(
              `${beUrl}/checkout`,
              { listingId: listing._id, additionalNotes: notes },
              { withCredentials: true }
            );
            checkoutResponse = data;
          } catch (checkoutError) {
            console.error("Checkout endpoint error:", checkoutError);
            toast.error("Error updating purchase information");
            setLoading(false);
            return;
          }
          // Redirect to Purchases page
          navigate("/purchases");
        } else {
          toast.error("Payment failed: " + (braintreeResponse ? braintreeResponse.error : "Unknown error"));
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error during payment:", error);
      toast.error("Error processing payment. Please try again.");
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-16 px-4 md:py-24">
      <h1 className="text-3xl font-bold text-center mb-10">Purchase Class</h1>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-2xl mx-auto bg-white shadow-lg rounded-lg p-8"
      >
        <h2 className="text-2xl font-semibold mb-4">
          {listing.isClassListing ? `Class: ${listing.className}` : "Offering to Hold Class"}
        </h2>
        <p className="text-gray-700 mb-2">College: {listing.collegeName}</p>
        <p className="text-gray-700 mb-2">
          Price: <span className="font-bold">${listing.price}</span>
        </p>
        <p className="text-gray-700 mb-2">Status: {listing.status}</p>
        <p className="text-gray-700 mb-2">Last Updated: {moment(listing.timeLastUpdated).format("LLL")}</p>
        <p className="text-gray-700 mb-4">Notes: {listing.notes}</p>

        {/* Payment Info Section */}
        <div className="border p-4 rounded-lg shadow-md mb-6">
          <h3 className="text-xl font-semibold mb-2 text-center">Enter Payment Information</h3>
          <div ref={dropinContainerRef} className="mb-4" />
          <div className="flex justify-center">
            <SwipeableButton
              onSuccess={handlePaymentSuccess}
              text="Swipe to Pay & Create Chat"
              text_unlocked="Processing..."
              color="#22c55e"
              style={{ fontSize: "1.25rem", padding: "1rem 2rem", width: "100%" }}
            />
          </div>
        </div>
      </motion.div>
      {loading && (
        <div className="flex justify-center items-center mt-4">
          <p className="text-gray-600">Processing payment...</p>
        </div>
      )}
    </div>
  );
};

const PurchasePage = () => {
  const location = useLocation();
  const { setProfileData } = useOutletContext();
  const listingDetails = location.state;
  const listing = listingDetails && listingDetails[0];

  if (!listing) {
    return <div>No listing selected.</div>;
  }

  return <PurchaseForm listing={listing} setProfileData={setProfileData} />;
};

export default PurchasePage;
