import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { toast } from "react-toastify";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import ConfirmationModal from "../../components/modals/ConfirmationModal";
import Image from "../../components/designLayouts/Image";

const Admin = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("pending");
  const [pendingListings, setPendingListings] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(true);
  const beUrl = process.env.REACT_APP_BACKEND_URL || "https://server.coursebidder.com";

  // Fetch pending listings (expect seller populated)
  const fetchPendingListings = async () => {
    try {
      const { data: { listing = [] } = {} } = await axios.get(
        `${beUrl}/get-listing-awaiting-for-approval`,
        { withCredentials: true }
      );
      setPendingListings(listing);
    } catch (error) {
      console.error("Error fetching pending listings:", error);
      toast.error("Error fetching pending listings");
    }
  };

  // Fetch transactions (expect seller and buyer populated if applicable)
  const fetchTransactions = async () => {
    try {
      const { data } = await axios.get(`${beUrl}/get-all-transactions`, { withCredentials: true });
      setTransactions(data || []);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      toast.error("Error fetching transactions");
    }
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([fetchPendingListings(), fetchTransactions()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const approveItem = async () => {
    try {
      const itemId = selectedItem._id;
      const res = await axios.put(`${beUrl}/approve-listing/${itemId}`, undefined, { withCredentials: true });
      if (res?.data?.error) toast.error(res.data.error);
      else toast.success(res.data.message);
      setPendingListings((prev) => prev.filter((node) => node._id !== selectedItem._id));
      setSelectedItem(null);
      setAction(null);
    } catch (error) {
      console.log(error);
      setSelectedItem(null);
      setAction(null);
    }
  };

  const denyItem = async () => {
    try {
      const itemId = selectedItem._id;
      const res = await axios.put(`${beUrl}/deny-listing/${itemId}`, undefined, { withCredentials: true });
      if (res?.data?.error) toast.error(res.data.error);
      else toast.success(res.data.message);
      setPendingListings((prev) => prev.filter((node) => node._id !== selectedItem._id));
      setSelectedItem(null);
      setAction(null);
    } catch (error) {
      console.log(error);
      setSelectedItem(null);
      setAction(null);
    }
  };

  const handleChat = (item) => {
    if (item.status === "Finalized") {
      toast.info("Chat is disabled for finalized transactions");
    } else if (item.chatId) {
      navigate(`/chat/${item.chatId}`);
    } else {
      toast.info("Chat not available");
    }
  };

  const renderPendingListings = () => (
    <div>
      <div className="w-full h-20 bg-gray-100 grid grid-cols-12 items-center px-6 text-lg font-semibold">
        <h2 className="col-span-5">Listing Information</h2>
        <h2 className="col-span-2">Price</h2>
        <h2 className="col-span-2">Status</h2>
        <h2 className="col-span-3">Actions</h2>
      </div>
      <div className="mt-5">
        {loading && (
          <div className="w-full flex items-center justify-center py-20">
            <div className="loader"></div>
          </div>
        )}
        {pendingListings.length === 0 && (
          <div className="w-full flex items-center justify-center py-6">
            <p className="text-lg font-bold">No listings to approve</p>
          </div>
        )}
        {pendingListings.map((item) => (
          <motion.div
            key={item._id}
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.4 }}
            className="grid grid-cols-12 items-center bg-white p-6 rounded-md shadow-md mb-4 text-lg"
          >
            <div className="col-span-5 flex gap-4 items-center">
              <div>
                {item.verificationImage && (
                  <Image
                    imgSrc={item.verificationImage}
                    className="w-[200px] h-[100px] object-cover rounded-md"
                  />
                )}
              </div>
              <div className="flex flex-col">
                <p className="font-semibold text-gray-800">
                  {item.isClassListing ? `Class: ${item.className}` : "Offering to Hold Class"}
                </p>
                <p className="text-sm text-gray-600">Notes: {item.notes}</p>
                <p className="text-xs text-gray-500">
                  Posted at: {new Date(item.timeLastUpdated).toLocaleString()}
                </p>
                <p className="text-xs text-gray-500">
                  Seller: {item.seller ? item.seller.email : "N/A"}
                </p>
              </div>
            </div>
            <div className="col-span-2">
              <p className="font-semibold text-gray-800">${item.price}</p>
            </div>
            <div className="col-span-2">
              <p className="font-bold bg-yellow-500 text-white px-2 py-1 rounded-xl w-fit">
                {item.status}
              </p>
            </div>
            <div className="col-span-3 flex space-x-4">
              <button
                onClick={() => {
                  setSelectedItem(item);
                  setAction("approve");
                  setConfirmationModal(true);
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Approve
              </button>
              <button
                onClick={() => {
                  setSelectedItem(item);
                  setAction("deny");
                  setConfirmationModal(true);
                }}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Deny
              </button>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );

  const renderTransactions = () => (
    <div className="space-y-6">
      {loading && (
        <div className="w-full flex items-center justify-center py-20">
          <div className="loader"></div>
        </div>
      )}
      {transactions.length === 0 ? (
        <div className="w-full flex items-center justify-center py-6">
          <p className="text-lg font-bold">No transactions available</p>
        </div>
      ) : (
        transactions.map((item) => (
          <motion.div
            key={item._id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            className="bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row items-start md:items-center gap-4"
          >
            <div className="flex-1">
              <h2 className="text-2xl font-semibold mb-2">
                {item.isClassListing ? `Class: ${item.className}` : "Offering to Hold Class"}
              </h2>
              <p className="text-gray-700 mb-1">
                Price: <span className="font-bold">${item.price}</span>
              </p>
              <p className="text-gray-700 mb-1">
                College: {item.collegeName}
              </p>
              <p className="text-gray-700 mb-1">
                Posted at: {new Date(item.timeLastUpdated).toLocaleString()}
              </p>
              <p className="text-gray-700 mb-1">
                Seller: <span className="font-bold">{item.seller ? item.seller.email : "N/A"}</span>
              </p>
              <p className="text-gray-700 mb-1">
                Buyer: <span className="font-bold">{item.buyer ? item.buyer.email : "N/A"}</span>
              </p>
              <p className="text-gray-500 mb-1">
                Purchased on: {item.purchasedOn ? new Date(item.purchasedOn).toLocaleString() : "N/A"}
              </p>
              {item.transaction && (
                <div className="mt-2 border-t pt-2">
                  <p className="text-gray-700">
                    Payment Status:{" "}
                    <span className="font-bold text-green-500">
                      {item.transaction.paymentStatus}
                    </span>
                  </p>
                  <p className="text-gray-700">
                    Transaction Amount:{" "}
                    <span className="font-bold">${item.transaction.amount}</span>{" "}
                    {item.transaction.currency}
                  </p>
                  <p className="text-gray-700">
                    Transaction Date:{" "}
                    {item.transaction.createdAt ? new Date(item.transaction.createdAt).toLocaleString() : "N/A"}
                  </p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <div>
                <p className={`font-bold text-gray-800`}>Status: {item.status}</p>
              </div>
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => handleChat(item)}
                  className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                >
                  View Chat
                </button>
              </div>
            </div>
          </motion.div>
        ))
      )}
    </div>
  );

  return (
    <>
      <div className="max-w-container mx-auto px-4 py-8">
        <Breadcrumbs title="Admin Panel" undercard="Manage Listings & Monitor Transactions" />
        <div className="flex space-x-4 mb-6">
          <button
            onClick={() => setActiveTab("pending")}
            className={`px-4 py-2 rounded-md font-semibold ${
              activeTab === "pending" ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-800"
            }`}
          >
            Pending Listings
          </button>
          <button
            onClick={() => setActiveTab("transactions")}
            className={`px-4 py-2 rounded-md font-semibold ${
              activeTab === "transactions" ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-800"
            }`}
          >
            Transactions
          </button>
        </div>
        {activeTab === "pending" ? renderPendingListings() : renderTransactions()}
      </div>
      <ConfirmationModal
        open={confirmationModal}
        onContinue={() => {
          setConfirmationModal(false);
          if (action === "approve") approveItem();
          else if (action === "deny") denyItem();
        }}
        onCancel={() => {
          setConfirmationModal(false);
        }}
      />
    </>
  );
};

export default Admin;
