import React from "react";

const Breadcrumbs = ({ title, undercard }) => {
  return (
    <div className="bg-white py-4">
      <div className="max-w-container mx-auto px-4">
        <h1 className="text-3xl font-sans font-bold text-bruinblue">
          {title}
        </h1>
        {undercard && (
          <p className="text-xl text-lightText mt-1">{undercard}</p>
        )}
      </div>
    </div>
  );
};

export default Breadcrumbs;
