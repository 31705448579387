import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import axios from "axios";
import { motion } from "framer-motion";
import { toast } from "react-toastify";

const UserListings = () => {
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(true);
  // States for editing
  const [editingListingId, setEditingListingId] = useState(null);
  const [editedPrice, setEditedPrice] = useState("");
  const [editedNotes, setEditedNotes] = useState("");
  const navigate = useNavigate();

  const beUrl = process.env.REACT_APP_BACKEND_URL || "https://server.coursebidder.com";

  const fetchListings = async () => {
    try {
      const response = await axios.get(`${beUrl}/getListingsFromUser`, {
        withCredentials: true,
      });
      setListings(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching listings:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const handleDelete = async (listingId) => {
    try {
      await axios.post(`${beUrl}/deleteListing`, { listingId }, { withCredentials: true });
      toast.success("Listing deleted successfully");
      fetchListings();
    } catch (error) {
      console.error("Error deleting listing:", error);
      toast.error("Error deleting listing");
    }
  };

  const startEditing = (listing) => {
    setEditingListingId(listing._id);
    setEditedPrice(listing.price);
    setEditedNotes(listing.notes);
  };

  const cancelEditing = () => {
    setEditingListingId(null);
    setEditedPrice("");
    setEditedNotes("");
  };

  const handleSave = async (listingId) => {
    try {
      await axios.post(
        `${beUrl}/updateListing`,
        { listingId, price: editedPrice, notes: editedNotes },
        { withCredentials: true }
      );
      toast.success("Listing updated successfully");
      cancelEditing();
      fetchListings();
    } catch (error) {
      console.error("Error updating listing:", error);
      toast.error("Error updating listing");
    }
  };

  const handleChat = (listing) => {
    if (listing.status === "Finalized") {
      toast.info("Chat is disabled for finalized listings");
    } else if (listing.chatId) {
      navigate(`/chat/${listing.chatId}`);
    } else {
      toast.info("Chat not available for this listing");
    }
  };

  // For seller's own listings: Confirm drop only if status is "Bought"
  const confirmDrop = async (listingId) => {
    try {
      await axios.put(`${beUrl}/confirm-drop/${listingId}`, {}, { withCredentials: true });
      toast.success("Class marked as dropped");
      fetchListings();
    } catch (error) {
      console.error("Error confirming drop:", error);
      toast.error("Error confirming drop");
    }
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <Breadcrumbs title="My Listings" undercard="Your listed courses to sell" />
      {loading ? (
        <div className="flex items-center justify-center py-20">
          <div className="loader"></div>
        </div>
      ) : listings.length > 0 ? (
        <div className="space-y-6">
          {listings.map((item) => (
            <motion.div
              key={item._id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col md:flex-row items-center justify-between"
            >
              <div className="flex-1">
                <h2 className="text-2xl font-semibold mb-2">
                  {item.isClassListing ? `Class: ${item.className}` : "Offering to Hold Class"}
                </h2>
                <p className="text-gray-700">College: {item.collegeName}</p>
                {editingListingId === item._id ? (
                  <>
                    <div className="mb-2">
                      <label className="block text-gray-700">Price:</label>
                      <input
                        type="number"
                        value={editedPrice}
                        onChange={(e) => setEditedPrice(e.target.value)}
                        className="w-full border rounded p-2"
                      />
                    </div>
                    <div className="mb-2">
                      <label className="block text-gray-700">Additional Notes:</label>
                      <input
                        type="text"
                        value={editedNotes}
                        onChange={(e) => setEditedNotes(e.target.value)}
                        className="w-full border rounded p-2"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-gray-700">
                      Price: <span className="font-bold">${item.price}</span>
                    </p>
                    <p className="text-gray-700">
                      Notes: <span className="font-bold">{item.notes}</span>
                    </p>
                  </>
                )}
                <p className="text-gray-700">Status: {item.status}</p>
                {item.purchasedOn && (
                  <p className="text-gray-600">
                    Purchased on: {new Date(item.purchasedOn).toLocaleString()}
                  </p>
                )}
              </div>
              <div className="flex space-x-4 mt-4 md:mt-0">
                {item.status === "Waiting for Approval" || item.status === "Available" ? (
                  editingListingId === item._id ? (
                    <>
                      <button
                        onClick={() => handleSave(item._id)}
                        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md"
                      >
                        Save
                      </button>
                      <button
                        onClick={cancelEditing}
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                      >
                        Cancel
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => startEditing(item)}
                        className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(item._id)}
                        className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                      >
                        Delete
                      </button>
                    </>
                  )
                ) : item.status === "Bought" ? (
                  <>
                    <button
                      onClick={() => confirmDrop(item._id)}
                      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                    >
                      Confirm Drop
                    </button>
                    {item.status !== "Finalized" && (
                      <button
                        onClick={() => handleChat(item)}
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                      >
                        Chat
                      </button>
                    )}
                  </>
                ) : item.status === "Dropped" ? (
                  // Remove the drop button after listing is Dropped. Only show Chat (if not finalized).
                  <>
                    {item.status !== "Finalized" && (
                      <button
                        onClick={() => handleChat(item)}
                        className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                      >
                        Chat
                      </button>
                    )}
                  </>
                ) : null}
              </div>
            </motion.div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center py-20">
          <h1 className="text-2xl font-bold text-gray-700">
            No current listings.
          </h1>
          <p className="text-gray-500 mt-2">
            Click Add Listing to list your first class!
          </p>
          <Link to="/addListing">
            <button className="mt-4 bg-primeColor rounded-md hover:bg-black active:bg-gray-900 px-8 py-2 font-semibold text-lg text-gray-200">
              Add Listing
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default UserListings;
