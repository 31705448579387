import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import colleges from "../../assets/colleges.json";
import Image from "../../components/designLayouts/Image";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import clsx from "clsx";
import sampleVerification from "../../assets/images/sampleVerification.png";
// IMPORTANT: Ensure you have installed braintree-web-drop-in
import dropin from "braintree-web-drop-in";

const AddListing = () => {
  const [collegeName, setCollegeName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isClassListing, setIsClassListing] = useState(false);
  const [className, setClassName] = useState("");
  const [price, setPrice] = useState("");
  const [notes, setNotes] = useState("");
  const [image, setImage] = useState(null);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const maxSize = 2 * 1024 * 1024;
  const navigate = useNavigate();

  // Payment info state for seller (Braintree)
  const [sellerClientToken, setSellerClientToken] = useState("");
  const [dropinInstance, setDropinInstance] = useState(null);
  const [paymentMethodNonce, setPaymentMethodNonce] = useState("");

  const [loading, setLoading] = useState(true);
  const dropinContainerRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";
        await axios.get(`${beUrl}/getUserFromSession`, { withCredentials: true });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("API call error:", error);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    const getClientToken = async () => {
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";
        const { data } = await axios.get(`${beUrl}/client_token`, { withCredentials: true });
        setSellerClientToken(data.clientToken);
      } catch (err) {
        console.error("Error fetching client token:", err);
      }
    };
    getClientToken();
  }, []);

  useEffect(() => {
    if (sellerClientToken && dropinContainerRef.current) {
      dropin.create(
        {
          authorization: sellerClientToken,
          container: dropinContainerRef.current,
        },
        (err, instance) => {
          if (err) {
            console.error("Dropin create error:", err);
            return;
          }
          setDropinInstance(instance);
        }
      );
    }
  }, [sellerClientToken]);

  const handlefile = (e) => {
    let selectedFile = e.target.files[0];
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
    if (!allowedTypes.includes(selectedFile.type)) {
      setFileErrorMessage("File type must be png or jpg");
    } else if (selectedFile.size > maxSize) {
      setFileErrorMessage("File size must be less than 2 MB");
    } else {
      setFileErrorMessage("");
      setImage(selectedFile);
    }
  };

  const resetForm = () => {
    setCollegeName("");
    setIsClassListing(false);
    setClassName("");
    setPrice("");
    setNotes("");
    setImage(null);
    setPaymentMethodNonce("");
  };

  const handleSavePaymentInfo = () => {
    if (!dropinInstance) {
      toast.error("Payment UI not initialized yet");
      return;
    }
    dropinInstance.requestPaymentMethod((err, payload) => {
      if (err) {
        console.error("Payment method error:", err);
        toast.error("Error capturing payment info");
        return;
      }
      setPaymentMethodNonce(payload.nonce);
      toast.success("Payment information saved");
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!paymentMethodNonce) {
      toast.error("Please save your payment information first");
      return;
    }
    if (!isSubmitting) {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("collegeName", collegeName);
      formData.append("isClassListing", isClassListing);
      formData.append("className", isClassListing ? className : "HOLDING_CLASS");
      formData.append("price", parseFloat(price));
      formData.append("notes", notes);
      formData.append("paymentMethodNonce", paymentMethodNonce);
      formData.append("image", image);
      try {
        const beUrl = process.env.REACT_APP_BACKEND_URL
          ? process.env.REACT_APP_BACKEND_URL
          : "https://server.coursebidder.com";
        const response = await axios.post(`${beUrl}/makeListing`, formData, {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("Response:", response);
        if (response.status === 201) {
          toast.success(response.data.message);
          resetForm();
          setTimeout(() => navigate("/userListings"), 2000);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error(error.response.data.error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  if (loading) {
    return (
      <div>
        <Breadcrumbs title="Add New Listing" undercard="Create a new listing for your items" />
        <div className="w-full flex items-center justify-center py-20">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto px-4 py-6">
      <Breadcrumbs
        title="Add New Listing"
        undercard="Create a new listing for your items"
        className="text-center text-xl font-bold mb-6"
      />
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-gray-700 text-lg font-medium mb-2">
            College Name
          </label>
          <select
            value={collegeName}
            onChange={(e) => setCollegeName(e.target.value)}
            className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="" disabled>
              Select a college
            </option>
            {colleges.colleges.map((college, index) => (
              <option key={index} value={college}>
                {college}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-gray-700 text-lg font-medium mb-2">
            Are you offering to hold a class or listing a specific class to sell?
          </label>
          <select
            value={isClassListing}
            onChange={(e) => setIsClassListing(e.target.value === "true")}
            className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value={false}>Offering to Hold Class</option>
            <option value={true}>Listing Specific Class</option>
          </select>
        </div>
        {isClassListing && (
          <div>
            <label className="block text-gray-700 text-lg font-medium mb-2">
              Class Name
            </label>
            <input
              type="text"
              value={className}
              onChange={(e) => setClassName(e.target.value)}
              className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required={isClassListing}
            />
          </div>
        )}
        <div>
          <label className="block text-gray-700 text-lg font-medium mb-2">
            Price
          </label>
          <div className="relative">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-lg text-gray-700">
              $
            </span>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full pl-8 px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>
        <div>
          <label className="block text-gray-700 text-lg font-medium mb-2">
            Notes (Lecture/Discussion Number, Special Enrollment/Major Priorities, etc):
          </label>
          <textarea
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            className="w-full px-4 py-3 text-base border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            rows="3"
          />
        </div>

        {/* Payment Info Section for Seller */}
        <div className="border p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-2 text-center">
            Payment Info (So you can get paid!)
          </h3>
          <div ref={dropinContainerRef} className="mb-4"></div>
          <button
            type="button"
            onClick={handleSavePaymentInfo}
            className="w-full bg-indigo-600 hover:bg-indigo-700 text-white py-2 rounded-md"
          >
            Save Payment Info
          </button>
          {paymentMethodNonce && (
            <p className="mt-2 text-sm text-green-600">
              Payment information saved.
            </p>
          )}
        </div>

        {/* Sample Verification Image */}
        <div className="mt-4">
          <label className="block text-gray-700 text-lg font-medium mb-2">
            Sample Verification Image - Ensure your image shows current enrollment
          </label>
          <img
            src={sampleVerification}
            alt="Sample Verification"
            className="w-full max-w-xs mx-auto border rounded-lg mt-4"
          />
        </div>
        <div>
          <h4 className="text-red-500">{fileErrorMessage && fileErrorMessage}</h4>
          <label className="block text-gray-700 text-lg font-medium mb-2">
            Verification Image
          </label>
          {!image && (
            <input
              type="file"
              required
              onChange={handlefile}
              className="w-full px-4 py-6 border rounded-lg border-dashed border-[#bfc1c4d6] focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          )}
        </div>
        <div className="border border-dashed rounded-md w-fit mx-auto mt-4">
          {image && (
            <div className="w-full h-auto border-[#bfc1c4d6]">
              <div className="flex justify-end m-2">
                <ImCross
                  onClick={() => setImage(null)}
                  className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
                />
              </div>
              <Image
                imgSrc={URL.createObjectURL(image)}
                className="w-full h-auto object-cover rounded-lg"
              />
            </div>
          )}
        </div>
        <div>
          <button
            disabled={isSubmitting}
            type="submit"
            className={clsx(
              isSubmitting
                ? "bg-gray-500 cursor-not-allowed "
                : "bg-blue-600 hover:bg-blue-700 active:bg-blue-800 cursor-pointer",
              "w-full px-6 py-3 text-white rounded-lg font-medium text-lg mt-4"
            )}
          >
            Create Listing
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddListing;
