import React from "react";
import { Link } from "react-router-dom";
import { 
  FaUserShield, 
  FaHandshake, 
  FaLock, 
  FaPlus, 
  FaEye, 
  FaShoppingCart, 
  FaComments, 
  FaBan, 
  FaCheckCircle, 
  FaArrowRight 
} from "react-icons/fa";
import { AiOutlineRobot } from "react-icons/ai";

const LandingPage = () => {
  return (
    <div className="min-h-screen flex flex-col bg-white font-sans">
      <main className="flex-1 w-full flex flex-col items-center pt-12">
        {/* Hero Section */}
        <section className="text-center my-16 w-full px-4 animate-fadeIn">
          <div className="max-w-7xl mx-auto">
            <h1 className="text-5xl lg:text-6xl font-extrabold text-gray-900 mb-6 tracking-wider">
              Welcome to CourseBidder!
            </h1>
            <p className="mt-4 text-2xl text-gray-800 leading-relaxed max-w-2xl mx-auto mb-10">
              An anonymous and secure platform for buying and selling class
              seats. Preventing scams and enhancing student-to-student private
              transactions.
            </p>
            <div className="mt-12 flex justify-center space-x-4">
              <Link to="/home">
                <button className="px-10 py-5 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-full shadow-lg transition-all duration-300 hover:scale-110 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-2xl">
                  Take Me to Classes
                </button>
              </Link>
              <Link to="/learn-more">
                <button className="px-8 py-4 bg-white text-blue-600 border border-blue-600 rounded-full shadow-lg transition-all duration-300 hover:bg-blue-50 hover:shadow-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 text-2xl">
                  Learn More
                </button>
              </Link>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        <section className="w-full bg-gray-100 py-20 animate-fadeIn">
          <div className="max-w-7xl mx-auto px-4 text-center">
            <h2 className="text-4xl font-extrabold text-gray-900 mb-8 tracking-wide">
              How It Works
            </h2>
            <p className="text-2xl text-gray-700 mb-10">
              Our process is built around your privacy, security, and authenticity. <br />
              Follow these steps to see how a simple course listing transforms into a secure, seamless transaction.
            </p>
            {/* Listing Process */}
            <div className="mb-12">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Listing a Course
              </h3>
              <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-4">
                {/* Step 1: Add a Listing */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <FaPlus className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">Add a Listing</p>
                  <p className="text-gray-600 text-center">
                    Post your class seat with complete privacy.
                  </p>
                </div>
                <div className="hidden md:flex items-center justify-center">
                  <FaArrowRight className="text-blue-600 text-4xl" />
                </div>
                {/* Step 2: Listing Becomes Available */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <FaEye className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">Available for Purchase</p>
                  <p className="text-gray-600 text-center">
                    Your listing is instantly viewable by buyers.
                  </p>
                </div>
                <div className="hidden md:flex items-center justify-center">
                  <FaArrowRight className="text-blue-600 text-4xl" />
                </div>
                {/* Step 3: Verified by AI */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <AiOutlineRobot className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">AI Verified</p>
                  <p className="text-gray-600 text-center">
                    Authenticity ensured with advanced verification.
                  </p>
                </div>
              </div>
            </div>
            
            {/* Purchase Process */}
            <div>
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">
                Buying a Course
              </h3>
              <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-4">
                {/* Step 1: Purchase a Listing */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <FaShoppingCart className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">Purchase a Listing</p>
                  <p className="text-gray-600 text-center">
                    Start a secure and private transaction.
                  </p>
                </div>
                <div className="hidden md:flex items-center justify-center">
                  <FaArrowRight className="text-blue-600 text-4xl" />
                </div>
                {/* Step 2: Secure Anonymous Chat */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <FaComments className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">Secure Chat</p>
                  <p className="text-gray-600 text-center">
                    Communicate anonymously between buyer and seller.
                  </p>
                </div>
                <div className="hidden md:flex items-center justify-center">
                  <FaArrowRight className="text-blue-600 text-4xl" />
                </div>
                {/* Step 3: Seller Marks as Dropped */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <FaBan className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">Mark as Dropped</p>
                  <p className="text-gray-600 text-center">
                    Seller updates the status when needed.
                  </p>
                </div>
                <div className="hidden md:flex items-center justify-center">
                  <FaArrowRight className="text-blue-600 text-4xl" />
                </div>
                {/* Step 4: Finalize Purchase */}
                <div className="flex flex-col items-center bg-white p-8 shadow-lg rounded-lg transition-all duration-300 hover:scale-105">
                  <FaCheckCircle className="text-blue-600 text-5xl mb-2" />
                  <p className="mt-2 text-xl font-semibold">Finalize Purchase</p>
                  <p className="text-gray-600 text-center">
                    Transaction completes securely and privately.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Our Promise & Mission Section */}
<section className="w-full bg-gray-50 py-20 animate-fadeIn">
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
    <h2 className="text-4xl font-extrabold text-gray-900 mb-8 tracking-wide">
      Our Promise & Mission
    </h2>
    <div className="max-w-4xl mx-auto">
      <p className="text-2xl text-gray-800 leading-relaxed mb-6">
        Our journey began with a simple observation: too many students were missing out on opportunities due to a flawed system of exchanging class seats. We dreamed of a world where trust and transparency would replace uncertainty and risk.
      </p>
      <p className="text-2xl text-gray-800 leading-relaxed mb-6">
        At CourseBidder, we built a platform that empowers students to take control of their academic futures. Our system is designed to be secure, private, and effortless—ensuring every transaction is protected with advanced AI verification and a state-of-the-art escrow system.
      </p>
      <p className="text-2xl text-gray-800 leading-relaxed mb-6">
        Every feature we create is driven by a single mission: to make class seat exchanges as seamless and stress-free as possible. We believe that when students are given a fair, safe, and transparent space to interact, incredible opportunities unfold. Our promise is to keep evolving, innovating, and fighting for your academic success—one secure transaction at a time.
      </p>
    </div>
  </div>
</section>
      </main>
    </div>
  );
};

export default LandingPage;
